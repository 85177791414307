import React, { lazy } from 'react'
import parse, { domToReact, attributesToProps } from 'html-react-parser'
import { Lazy } from '../content'
import { Box, Placeholder, LinkExternalSafe } from '../ui'

const PostGallery = lazy(() => import('./PostGallery'))

const EMPTY_NODE = <></>

// TODO write recursive search func when not tired
const searchImages = (node) => node
  .children
  .map((child) => {
    try {
      return child?.children[1].children[1].children[0]
    } catch (error) {
      return null
    }
  })
  .filter((v) => !!v)

const replacers = {
  removeStyleTags: ({ name }) => (
    name === 'style' ? EMPTY_NODE : null
  ),

  replaceLegacyGalleries: (node) => {
    const isGallery = node?.attribs?.id?.includes('gallery-')
      && node?.attribs?.class?.includes('galleryid-')

    if (isGallery) {
      const imgNodes = searchImages(node)
      const transformedItems = imgNodes.map((item) => ({
        alt: item.attribs.alt,
        thumbnailUrl: item.attribs.src,
        originalUrl: item.parent.attribs.href,
      }))

      return (
        <Box
          my={4}
          mx={[-3, -3, -3, 0]}
          className="post-gallery"
        >
          <Lazy fallback={<Placeholder height={[250, 300, 550]} />}>
            <PostGallery items={transformedItems} />
          </Lazy>
          <noscript>
            {
              node.children?.length
                ? domToReact(node.children)
                : null
            }
          </noscript>
        </Box>
      )
    }

    return null
  },

  replaceAnchors: ({ name, attribs, children }) => {
    if (name === 'a') {
      return (
        <LinkExternalSafe
          {...attributesToProps(attribs)}
          variant="primary"
        >
          {domToReact(children || '')}
        </LinkExternalSafe>
      )
    }

    return null
  },
}

export default function parsePostContent(content) {
  const replace = (node) => (
    Object
      .keys(replacers)
      .reduce((lastValue, key) => (
        replacers[key](node) || lastValue
      ), null)
  )

  return parse(content || '', { replace })
}
