import React from 'react'
import { Helmet } from 'react-helmet'

const MetaTagsLocation = ({ date, author }) => (
  <Helmet>
    <meta
      property="og:type"
      content="article"
    />
    <meta
      property="og:date"
      content={date}
    />
    <meta
      property="og:author"
      content={author}
    />
  </Helmet>
)

export default MetaTagsLocation
