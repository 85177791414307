import React from 'react'
import { Helmet } from 'react-helmet'

const MetaTagsMustHave = ({ title, description, opengraphImage }) => (
  <>
    {
      title && (
        <Helmet>
          <title>{title}</title>
          <meta
            property="og:title"
            content={title}
          />
          <meta
            name="twitter:title"
            content={title}
          />
        </Helmet>
      )
    }

    {
      description && (
        <Helmet>
          <meta
            name="description"
            content={description}
          />
          <meta
            property="og:description"
            content={description}
          />
          <meta
            name="twitter:description"
            content={description}
          />
        </Helmet>
      )
    }

    {
      opengraphImage && opengraphImage.src && (
        <Helmet>
          <meta
            property="og:image"
            content={`${opengraphImage.src}`}
          />
          <meta
            property="og:image:secure_url"
            content={opengraphImage.src}
          />
        </Helmet>
      )
    }
  </>
)

export default MetaTagsMustHave
