import React from 'react'
import { Helmet } from 'react-helmet'

const SchemaLocalBusiness = ({
  url, title, description, address = '', town = '', region = '', country = '', lat = '', lng = '', opengraphImage = {},
}) => {
  const stucturedData = {
    '@context': 'https://schema.org',
    '@type': 'LocalBusiness',
    name: title,
    description,
    image: opengraphImage ? opengraphImage.src : '',
    url: `${process.env.SITE_BASE_URL}${url}`,
    address: {
      '@type': 'Text',
      streetAddress: address,
      addressLocality: town,
      addressRegion: region,
      addressCountry: country,
    },
    geo: {
      '@type': 'GeoCoordinates',
      latitude: lat,
      longitude: lng,
    },
  }

  return (
    <Helmet>
      <script type="application /ld+json">
        {JSON.stringify(stucturedData, null, 2)}
      </script>
    </Helmet>
  )
}

export default SchemaLocalBusiness
